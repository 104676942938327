// routes
import AppRouting from "./utils/routing";

// styles
import "./App.css";
import "./styles/styles.css";

function App() {
  return <AppRouting />;
}

export default App;
